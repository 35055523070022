import { useState, useEffect, useRef } from "react";
import styles from "./announcement.module.css";

const popupImages = [
	"/img/announcement/notification_25_03_24.png",
	// "/img/announcement/notification_25_03_12.png",
];

const Announcement = () => {
	const [isOpen, setIsOpen] = useState(true);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [showUI, setShowUI] = useState(false); // 기본은 숨김 상태
	const modalRef = useRef(null);
	const intervalRef = useRef(null);

	const closeModal = () => {
		setIsOpen(false);
		clearInterval(intervalRef.current);
	};

	const goPrev = () => {
		setCurrentIndex((prev) => (prev - 1 + popupImages.length) % popupImages.length);
	};

	const goNext = () => {
		setCurrentIndex((prev) => (prev + 1) % popupImages.length);
	};


	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") closeModal();
		};

		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	if (!isOpen) return null;

	return (
		<div className={styles.modalOverlay}>
			<div className={styles.modalContent} ref={modalRef}>
			<button className={styles.closeButton} onClick={closeModal} aria-label="닫기">
				<span className={styles.closeContent} >&times;</span>
			</button>
				<div
					className={styles.imageContainer}
					onMouseEnter={() => setShowUI(true)}
					onMouseLeave={() => setShowUI(false)}
				>
					<img
						src={popupImages[currentIndex]}
						alt={`Announcement ${currentIndex + 1}`}
						className={styles.announcementImage}
					/>

					{showUI && (
						<>
							<button onClick={goPrev} className={`${styles.navButton} ${styles.navLeft}`}>
								&#8249;
							</button>
							<button onClick={goNext} className={`${styles.navButton} ${styles.navRight}`}>
								&#8250;
							</button>
							<span className={styles.pageIndicator}>
								{currentIndex + 1} / {popupImages.length}
							</span>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Announcement;